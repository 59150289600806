// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-base/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-lists/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-popups/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(http://fonts.cdnfonts.com/css/primer-print);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#zmmtg-root {\n  display: none; }\n", "",{"version":3,"sources":["webpack://src/index.scss"],"names":[],"mappings":"AAIA;EACE,aAAa,EAAA","sourcesContent":["// body {\n//   overflow: hidden;\n// }\n\n#zmmtg-root {\n  display: none;\n}\n\n@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap\");\n@import url(\"http://fonts.cdnfonts.com/css/primer-print\");\n@import url(\"https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap\");\n\n//Add scheduler component’s styles ( TimeTable Component)\n@import \"../node_modules/@syncfusion/ej2-base/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-buttons/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-calendars/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-dropdowns/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-inputs/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-lists/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-navigations/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-popups/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-react-schedule/styles/material.css\";\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
